<template>
  <b-card
    no-body
  >
    <div class="bg-light-primary rounded-top text-center border-bottom" :style="styleDefault">
      <b-img
        :src="promotionHotSite"
        alt="Screenshot da promoção"
        height="170"
      />
    </div>
    <b-card-body style="padding: 4px !important;">
      <b-row class="p-0">
        <b-col sm="12" md="6" class="pl-2 pt-2 pr-0">
          <h4>Resumo {{ currentPrizeDraw.name }}</h4>
          <b>Séries válidas: </b><br>
          <h5 style="color:var(--blue-text)">
            {{ currentPrizeDraw.settings.start_serie }} - {{ currentPrizeDraw.settings.end_serie }}
          </h5>
          <b>Intervalo de Números </b><br>
          <h5 style="color:var(--blue-text)">
            {{ minNumber }} - {{ maxNumber }}
          </h5>
        </b-col>
        <b-col sm="12" md="6" class="pr-1" >
          <numbers-by-promotion-radial-chart/>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import NumbersByPromotionRadialChart from '@/views/dashboards/metrics/NumbersByPromotionRadialChart.vue'

export default {
  name: 'PromotionResume',
  components: { NumbersByPromotionRadialChart },
  props: {
    currentPrizeDraw: {},
    currentPromotion: {},
    promotionHotSite: {},
  },
  data() {
    return {
      minNumber: null,
      maxNumber: null,
      styleDefault: '',
    }
  },
  watch: {
    currentPrizeDraw(value) {
      if (value.uuid) {
        this.getPrizeDrawMinAndMaxNumber()
      }
    },
  },
  mounted() {
    this.getPrizeDrawMinAndMaxNumber()
  },
  methods: {

    async getPrizeDrawMinAndMaxNumber() {
      if (!this.currentPrizeDraw.uuid) {
        return
      }
      if (this.currentPromotion.settings.background_color) {
        this.styleDefault = `background-color: ${this.currentPromotion.settings.background_color} !important;`
      }
      const response = await this.$http.get(`/metrics/prize-draws/${this.currentPrizeDraw.uuid}/min-max-numbers`)
      if (!response.error) {
        this.minNumber = response.min_number
        this.maxNumber = response.max_number
      }
    },
  },
}
</script>
