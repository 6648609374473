<template>
  <div class="loading-bar">
    <div class="loading-1"/>
    <div class="loading-2"/>
    <div class="loading-3"/>
    <div class="loading-4"/>
  </div>
</template>

<script>
export default {
  name: 'I2DataLoading',
}
</script>

<style scoped>
.loading-bar {
  height: 45px;
  width: 100px;
  position: absolute;
  left: 40%;
  top: 30%;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  box-sizing: border-box;
}

@keyframes loading-bar {
  0% {
    background-color: #82868b;
  }
  30% {
    background-color: #8f9397;
  }
  50% {
    height: 40px;
    margin-top: 0px;
  }
  80% {
    background-color: #696d71;
  }
  100% {
    background-color: #5d6064;
  }
}
/*@-moz-keyframes loading-bar {
  50% { height: 100px; margin-top: 0px; }
}
@-o-keyframes loading-bar {
  50% { height: 100px; margin-top: 0px; }
}
@keyframes loading-bar {
  50% { height: 100px; margin-top: 0px; }
}*/
.loading-bar .loading-1 {
  height: 0px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  margin-top: 30px;
  -webkit-animation: loading-bar 2.5s infinite;
  -moz-animation: loading-bar 2.5s infinite;
  -o-animation: loading-bar 2.5s infinite;
  animation: loading-bar 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.loading-bar .loading-2 {
  height: 0px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  margin-top: 30px;
  -webkit-animation: loading-bar 2.5s infinite;
  -moz-animation: loading-bar 2.5s infinite;
  -o-animation: loading-bar 2.5s infinite;
  animation: loading-bar 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.loading-bar .loading-3 {
  height: 0px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  margin-top: 30px;
  -webkit-animation: loading-bar 2.5s infinite;
  -moz-animation: loading-bar 2.5s infinite;
  -o-animation: loading-bar 2.5s infinite;
  animation: loading-bar 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.loading-bar .loading-4 {
  height: 10px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  margin-top: 30px;
  -webkit-animation: loading-bar 2.5s infinite;
  -moz-animation: loading-bar 2.5s infinite;
  -o-animation: loading-bar 2.5s infinite;
  animation: loading-bar 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
</style>
