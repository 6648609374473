<template>
  <vue-apex-charts
    type="radialBar"
    :height="height"
    :options="chartOptions"
    :title="title"
    :series="series"
  />
</template>
<script>

import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'

const chartColors = [
  '#ffe700',
  '#00d4bd',
  '#826bf8',
  '#2b9bf4',
  '#FFA1A1',
]
export default {
  name: 'RadialBarChart',
  components: {
    VueApexCharts,
  },
  props: {
    height: {
      type: [Number, String],
      required: true,
      default: 400,
    },
    width: {
      type: [Number, String],
      required: true,
      default: 400,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
    colors: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          series: [],
          labels: [],
          colors: [],
        }
      },
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      series: this.values.series,
      defaultOptions: {
        zoom: true,
        xAxysGrid: true,
        yAxysGrid: true,
        dataLabels: true,
        labelFontSize: '14px',
      },
      chartOptions: {
        theme: {
          mode: 'light',
        },
        chart: {
          foreColor: '#373d3f',
          toolbar: {
            show: this.toolbar,
          },
          redrawOnParentResize: true,
        },
        tooltip: {
          theme: 'light',
        },
        colors: this.values.colors || chartColors,
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '40%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                fontSize: '14px',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '14px',
                fontFamily: 'Montserrat',
              },
              total: {
                show: this.showTotal,
                fontSize: '1rem',
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        title: {
          text: this.title,
          align: 'left',
        },
        legend: {
          show: true,
          position: 'bottom',
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
          formatter(seriesName, opts) {
            return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}%`
          },
        },

        itemMargin: {
          vertical: 3,
        },
        stroke: {
          // lineCap: 'round',
        },
        labels: this.values.labels,
      },
    }
  },
  computed: {
    ...mapState(['appConfig/layout']),
  },
  watch: {
    values(data) {
      this.series = data.series
      const newOptions = { ...this.chartOptions }
      newOptions.labels = data.labels
      this.chartOptions = newOptions
      this.$forceUpdate()
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'appConfig/UPDATE_SKIN') {
        const newOptions = { ...this.chartOptions }
        newOptions.theme = {
          mode: mutation.payload,
        }

        newOptions.tooltip.theme = mutation.payload
        newOptions.chart.foreColor = mutation.payload === 'dark' ? '#f6f7f8' : '#373d3f'
        this.chartOptions = newOptions
        this.$forceUpdate()
      }
    })
  },
  mounted() {
    const theme = window.localStorage.getItem('vuexy-skin') || 'light'
    const newOptions = { ...this.chartOptions }
    const options = Object.assign(this.defaultOptions, this.options)
    newOptions.theme.mode = theme
    newOptions.tooltip.theme = theme
    newOptions.chart.foreColor = theme === 'dark' ? '#f6f7f8' : '#373d3f'
    newOptions.dataLabels.enabled = options.dataLabels
    newOptions.legend.fontSize = options.labelFontSize
    this.chartOptions = newOptions
  },

  beforeDestroy() {
    this.unsubscribe()
  },
}
</script>
