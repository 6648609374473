<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-row v-if="loading" style="height: 350px;" class="text-center">
        <b-col
          sm="12"
          align-self="center"
        >
          <i2-data-loading/>
        </b-col>
      </b-row>
      <mixed-chart v-if="!loading"
                   height="350" :values="series"
                   :toolbar="true"
                   :y-label="yLabel"
                   title=" Novos Participantes e Participantes Totais"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'
import MixedChart from '@/components/i2/Graph/MixedChart'

export default {
  name: 'ParticipantsPromotionChart',
  components: {
    MixedChart,
    I2DataLoading,
  },
  data() {
    return {
      dates: [],
      initialValue: [],
      colors: ['#5CC979'],
      loading: false,
      series: {},
      currentPromotion: {},
      currentPrizeDraw: {},
      yLabel: {
        text: 'Quantidades',
        format: val => new Intl.NumberFormat('pt-BR').format(val),
      },
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      return
    }
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      return
    }
    this.currentPromotion = promotion
    this.currentPrizeDraw = prizeDraw

    this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`metrics/prize-draws/${this.currentPrizeDraw.uuid}/participants`)
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response
        })
    },
  },
}
</script>

<style scoped>

</style>
