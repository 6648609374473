<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col sm="6" md="8">
        <numbers-and-participant-chart/>
      </b-col>
      <b-col sm="6" md="4">
        <promotion-resume :current-prize-draw="currentPrizeDraw" :promotion-hot-site="promotionHotSite" :current-promotion="currentPromotion"/>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col sm="12" md="6">
        <participants-promotion-chart/>
      </b-col>
      <b-col sm="12" md="6">
        <numbers-and-participants-prize-draw-chart/>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col sm="12" md="12">
        <numbers-and-participants-cfd-chart/>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col v-if="currentPrizeDraw.has_segments" sm="12" md="12">
        <ChartBarNumbersBySegment/>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col sm="12" md="12">
        <ChartBarNumbersByProduct/>
      </b-col>
    </b-row>
  </section>

</template>

<script>
import { mapActions } from 'vuex'
import NumbersAndParticipantChart from '@/views/dashboards/metrics/NumbersAndParticipantChart.vue'
import NumbersAndParticipantsPrizeDrawChart from '@/views/dashboards/metrics/NumbersAndParticipantsPrizeDrawChart.vue'
import NumbersAndParticipantsCfdChart from '@/views/dashboards/metrics/NumbersAndParticipantsCfdChart.vue'
import PromotionResume from '@/views/PromotionResume'
import ParticipantsPromotionChart from '@/views/dashboards/metrics/ParticipantsPromotionChart'
import { getUserData } from '@/auth/utils'
import ChartBarNumbersByProduct from '@/views/dashboards/metrics/ChartBarNumbersByProduct.vue'
import ChartBarNumbersBySegment from '@/views/dashboards/metrics/ChartBarNumbersBySegment.vue'

export default {
  components: {
    ChartBarNumbersBySegment,
    ChartBarNumbersByProduct,
    ParticipantsPromotionChart,
    PromotionResume,
    NumbersAndParticipantsCfdChart,
    NumbersAndParticipantsPrizeDrawChart,
    NumbersAndParticipantChart,

  },
  data() {
    return {
      promotionHotSite: `${process.env.VUE_APP_URL}/storage/screenshots/default-screenshot.png`,
      currentPromotion: null,
      currentPrizeDraw: {
        name: ' ',
        settings: {
          start_serie: '0',
          end_serie: '-',
        },
      },
    }
  },
  async created() {
    await this.me()
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }

    const responsePromotion = await this.$http.get(`promotions/${promotion.uuid}`)
    if (responsePromotion.settings.hotsite_url) {
      this.promotionHotSite = responsePromotion.hotsite_screenshot
      this.currentPromotion = responsePromotion
    }

    const prizeDraw = this.$user.getCurrentPrizeDraw()

    if (!prizeDraw) {
      this.$router.push('change-promotion.index')
      return
    }
    this.currentPrizeDraw = prizeDraw
  },
  methods: {
    ...mapActions('auth', ['me']),
  },
}
</script>
<style>

</style>
