<template>
  <b-card no-body>
    <b-card-header style="padding: 3px">
      <div>
        <b-card-title
          v-if="title"
          class="mb-1"
        >
          {{ title }}
        </b-card-title>
        <b-card-sub-title
          v-if="subTitle"
        >{{ subTitle }}
        </b-card-sub-title>
      </div>
    </b-card-header>
    <b-card-body style="padding: 1px;margin: 0px">
      <vue-apex-charts
        ref="graph"
        type="bar"
        :height="height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'

export default {
  name: 'BarChart',
  components: {
    VueApexCharts,
  },
  props: {
    height: {
      type: [Number, String],
      required: true,
      default: 400,
    },
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    colors: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          series: [],
          labels: [],
        }
      },

    },
    yLabel: {
      type: Object,
      required: false,
      default() {
        return {
          text: '',
          format: val => val,
        }
      },
    },
    xLabel: {
      type: Object,
      required: false,
      default() {
        return {
          text: '',
          format: val => val,
        }
      },
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {
        }
      },
    },
  },
  data() {
    const self = this
    return {
      series: this.values.series,
      defaultOptions: {
        zoom: true,
        xAxysGrid: true,
        yAxysGrid: true,
        dataLabels: true,
      },
      chartOptions: {
        theme: {
          mode: 'light',
        },
        chart: {
          foreColor: '#373d3f',
          stacked: this.stacked,
          toolbar: {
            show: this.toolbar,
          },
          redrawOnParentResize: true,
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: true,
            autoScaleXaxis: true,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4,
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1,
              },
            },
          },
        },
        tooltip: {
          theme: 'light',
        },
        noData: {
          text: 'Sem dados para exibir',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
          },
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: this.horizontal,
            barHeight: '98%',

            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
          hideOverflowingLabels: true,
          textAnchor: 'end',
          background: {
            foreColor: '#000',
          },
          style: {
            colors: ['000'],
          },

        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
            labels: {
              offsetX: -20,
              lineHeight: 10,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
            labels: {
              offsetX: -20,
              lineHeight: 10,
            },
          },
        },
        xaxis: {
          categories: this.values.labels,
          labels: {
            formatter(val) {
              if (self.xLabel.format) {
                return self.xLabel.format(val)
              }
              return val
            },
          },
          title: {
            text: this.xLabel?.text,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return self.yLabel.format(val)
            },
            lineHeight: 30,
          },
          title: {
            text: this.yLabel.text,
          },
        },
        fill: {
          opacity: 1,
        },
      },
      labels: {
        style: {
          fontSize: '125px',
        },
        lineHeight: 20,
      },
    }
  },
  computed: {
    ...mapState(['appConfig/layout']),
  },
  watch: {
    values(data) {
      this.series = data.series
      const newOptions = { ...this.chartOptions }
      newOptions.xaxis.categories = data.labels
      this.chartOptions = newOptions
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'appConfig/UPDATE_SKIN') {
        const newOptions = { ...this.chartOptions }
        newOptions.theme = {
          mode: mutation.payload,
        }
        newOptions.tooltip.theme = mutation.payload
        newOptions.chart.foreColor = mutation.payload === 'dark' ? '#f6f7f8' : '#373d3f'
        newOptions.dataLabels.style.colors[0] = mutation.payload === 'dark' ? '#FFF' : '#000'
        this.chartOptions = newOptions
        this.$forceUpdate()
      }
    })
  },
  mounted() {
    const theme = window.localStorage.getItem('vuexy-skin') || 'light'
    const newOptions = { ...this.chartOptions }
    const options = Object.assign(this.defaultOptions, this.options)
    newOptions.grid.xaxis.lines.show = options.xAxysGrid
    newOptions.theme.mode = theme
    newOptions.tooltip.theme = theme
    newOptions.chart.foreColor = theme === 'dark' ? '#f6f7f8' : '#373d3f'
    newOptions.grid.yaxis.lines.show = options.yAxysGrid
    newOptions.chart.zoom.enabled = options.zoom
    newOptions.dataLabels.enabled = options.dataLabels
    newOptions.dataLabels.textAnchor = 'end'
    newOptions.dataLabels.style.colors[0] = theme === 'dark' ? '#FFF' : '#000'
    this.chartOptions = newOptions
  },

  beforeDestroy() {
    this.unsubscribe()
  },
}
</script>
