<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title
          v-if="title"
          class="mb-1"
        >
          {{ title }}
        </b-card-title>
        <b-card-sub-title
          v-if="subTitle"
        >{{ subTitle }}
        </b-card-sub-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <vue-apex-charts
        type="line"
        :height="300"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MixedChart',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    height: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          series: [],
          labels: [],
        }
      },
    },
    yLabel: {
      type: Object,
      required: false,
      default() {
        return {
          text: '',
          format: val => val,
        }
      },
    },
  },
  data() {
    const self = this
    return {
      series: this.values.series,
      defaultOptions: {
        zoom: true,
        xAxysGrid: true,
        yAxysGrid: true,
        dataLabels: true,
      },
      chartOptions: {
        theme: {
          mode: 'light',
        },
        chart: {
          foreColor: '#373d3f',
          zoom: {
            enabled: true,
          },
          redrawOnParentResize: true,
          toolbar: {
            show: this.toolbar,
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          theme: 'light',
        },
        xaxis: {
          categories: this.values.labels,
        },
        yaxis: {
          labels: {
            formatter(val) {
              return self.yLabel.format(val)
            },
          },
          title: {
            text: this.yLabel.text,
          },
        },
      },
    }
  },
  computed: {
    ...mapState(['appConfig/layout']),
  },
  watch: {
    values(data) {
      this.series = data.series

      const newOptions = { ...this.chartOptions }
      newOptions.xaxis.categories = data.labels

      this.chartOptions = newOptions
      this.$forceUpdate()
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'appConfig/UPDATE_SKIN') {
        const newOptions = { ...this.chartOptions }
        newOptions.theme = {
          mode: mutation.payload,
        }
        newOptions.tooltip.theme = mutation.payload
        newOptions.chart.foreColor = mutation.payload === 'dark' ? '#f6f7f8' : '#373d3f'
        this.chartOptions = newOptions
        this.$forceUpdate()
      }
      if (mutation.type === 'verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED') {
        this.$forceUpdate()
      }
    })
  },
  mounted() {
    const theme = window.localStorage.getItem('vuexy-skin') || 'light'
    const newOptions = { ...this.chartOptions }
    newOptions.theme.mode = theme
    newOptions.tooltip.theme = theme
    newOptions.xaxis.categories = this.values.labels
    newOptions.chart.foreColor = theme === 'dark' ? '#f6f7f8' : '#373d3f'
    // newOptions.dataLabels.enabled = options.dataLabels
    this.chartOptions = newOptions
  },

}
</script>
