<template>
  <b-card
    no-body
  >
    <b-card-body style="" >
      <div class="text-right">
        <b-dropdown
          :text="'Mostrar: '+ defaultGroup.label"
          variant="transparent"
          class="chart-dropdown"
          right
          no-caret
          size="sm"
          style="z-index:10000"
        >
          <b-dropdown-item
            v-for="group in groupBy"
            :key="group.value"
            @click="setGroupBy(group)"
          >
            {{ group.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-row v-if="loading" style="height: 100%;" class="text-center">
        <b-col
          sm="12"
          align-self="center"
        >
          <i2-data-loading/></b-col>
      </b-row>
      <bar-chart v-if="!loading"
                 :horizontal="true"
                 :height="height"
                 :toolbar="true"
                 :y-label="yLabel"
                 :x-label="xLabel"
                 :options="{xAxysGrid: true, yAxysGrid:true, dataLabels: false}"
                 :values="series"
                 title="Números Por Segmento"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'
import BarChart from '@/components/i2/Graph/BarChart.vue'
import { isNumeric } from 'echarts/lib/util/number'

export default {
  name: 'ChartBarNumbersBySegment',
  components: {
    BarChart,
    I2DataLoading,
  },
  data() {
    return {
      dates: [],
      yLabel: {
        text: 'Segmentos',
        format: val => {
          if (isNumeric(val)) {
            return new Intl.NumberFormat('pt-BR').format(val)
          }
          return val
        },
      },
      xLabel: {
        text: 'Números',
        format: val => new Intl.NumberFormat('pt-BR').format(val),
      },
      groupBy: [
        { value: '', label: 'Todos' },
        { value: '10', label: '10 Maiores' },
      ],
      initialValue: [],
      colors: ['#45AEDC'],
      height: 350,
      defaultGroup: null,
      loading: false,
      series: {},
      chartOptions: {},
      isSinglePrizeDraw: false,
      currentPromotion: null,
      currentPrizeDraw: null,
      totalParticipants: 0,
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      return
    }
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      return
    }
    this.currentPromotion = promotion
    this.currentPrizeDraw = prizeDraw
    const defaultGroup = window.localStorage.getItem('statistics_segments_group_value') || '10'
    if (defaultGroup !== '10') {
      this.height = 1200
    }
    this.defaultGroup = this.groupBy.find(item => item.value === defaultGroup)
    this.getChartData()
  },
  methods: {
    setGroupBy(group) {
      this.defaultGroup = group
      window.localStorage.setItem('statistics_segments_group_value', group.value)
      this.height = 350
      if (group.value !== '10') {
        this.height = 1200
      }
      this.getChartData()
    },
    getChartData() {
      if (!this.currentPrizeDraw) {
        return
      }
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`metrics/prize-draws/${this.currentPrizeDraw.uuid}/numbers-by-segment?limit=${this.defaultGroup.value}`)
        .then(response => {
          console.log(response)
          this.loading = false
          this.$http.showLoader = true
          this.series = response
        })
    },
  },
}
</script>

<style scoped>

.participants-total {
  font-size: 90px;
  color: #45AEDC;
}

</style>
