<template>
  <div>
    <b-card
      class="p-0"
      no-body
    >
      <b-card-body class="p-0" >
        <b-row v-if="loading" style="height: 350px;" class="text-center">
          <b-col
            sm="12"
            align-self="center"
          >
            <i2-data-loading/>
          </b-col>
        </b-row>
        <radial-bar-chart v-if="!loading"
                          height="300"
                          width="300"
                          :values="series"
                          :show-total="false"
                          :options="{labelFontSize: '10px'}"
                          title="" theme="palette1"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'
import RadialBarChart from '@/components/i2/Graph/RadialBarChart.vue'

export default {
  name: 'NumbersByPromotionRadialChart',
  components: {
    RadialBarChart,
    I2DataLoading,
  },
  data() {
    return {
      dates: [],
      initialValue: [],
      colors: ['#5CC979'],
      loading: false,
      series: {},
      currentPromotion: {},
      currentPrizeDraw: {},
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      return
    }
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      return
    }
    this.currentPromotion = promotion
    this.currentPrizeDraw = prizeDraw
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`/metrics/promotion/${this.currentPromotion.uuid}/prize-draw/${this.currentPrizeDraw.uuid}/resume`)
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response
        })
    },
  },
}
</script>

<style scoped>

</style>
