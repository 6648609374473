<template>
  <b-card
    v-if="isShow"
    no-body
  >
    <b-card-body style="" >
      <b-row v-if="loading" style="height: 100%;" class="text-center">
        <b-col
          sm="12"
          align-self="center"
        >
          <i2-data-loading/></b-col>
      </b-row>
      <bar-chart v-if="!loading"
                 :horizontal="true"
                 height="350"
                 :toolbar="true"
                 :y-label="yLabel"
                 :x-label="xLabel"
                 :options="{xAxysGrid: true, yAxysGrid:true, dataLabels: false}"
                 :values="series" title="Números Por Produto"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'
import BarChart from '@/components/i2/Graph/BarChart.vue'
import { isNumeric } from 'echarts/lib/util/number'

export default {
  name: 'ChartBarNumbersByProduct',
  components: {
    BarChart,
    I2DataLoading,
  },
  data() {
    return {
      dates: [],
      yLabel: {
        text: 'Produtos',
        format: val => {
          if (isNumeric(val)) {
            return new Intl.NumberFormat('pt-BR').format(val)
          }
          return val
        },
      },
      xLabel: {
        text: 'Números',
        format: val => new Intl.NumberFormat('pt-BR').format(val),
      },
      initialValue: [],
      colors: ['#45AEDC'],
      loading: false,
      series: {},
      chartOptions: {},
      isSinglePrizeDraw: false,
      currentPromotion: null,
      currentPrizeDraw: null,
      totalParticipants: 0,
      isShow: true,
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      return
    }
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      return
    }
    this.currentPromotion = promotion
    this.currentPrizeDraw = prizeDraw
    this.getChartData()
  },
  methods: {
    getChartData() {
      if (!this.currentPrizeDraw) {
        return
      }
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`metrics/prize-draws/${this.currentPrizeDraw.uuid}/numbers-by-product`)
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response
          if (response.labels.length === 0 || response.series.length === 0) {
            this.isShow = false
          }
        })
    },
  },
}
</script>

<style scoped>

.participants-total {
  font-size: 90px;
  color: #45AEDC;
}

</style>
