<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-row v-if="loading" style="height: 350px;" class="text-center">
        <b-col
          sm="12"
          align-self="center"
        >
          <i2-data-loading/>
        </b-col>
      </b-row>
      <line-area-chart v-if="!loading"
                       height="300"
                       :toolbar="true"
                       :stacked="true"
                       :options="{xAxysGrid: true, yAxysGrid:true, dataLabels: true}"
                       :values="series"
                       :y-label="yLabel"
                       title="Quantidade de Números e Participantes Acumulados"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import LineAreaChart from '@/components/i2/Graph/LineAreaChart.vue'
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'

export default {
  name: 'NumbersAndParticipantsCfdChart',
  components: {
    LineAreaChart,
    I2DataLoading,
  },
  data() {
    return {
      dates: [],
      initialValue: [],
      loading: false,
      series: {},
      currentPromotion: {},
      yLabel: {
        text: 'Quantidades',
        format: val => new Intl.NumberFormat('pt-BR').format(val),
      },
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      return
    }
    this.currentPromotion = promotion
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`/metrics/promotion/${this.currentPromotion.uuid}/cumulative-numbers-participants-prize-draws`)
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response
        })
    },
  },
}
</script>

<style scoped>

</style>
