<template>
  <div>
    <b-card
      no-body
    >
      <b-card-body>
        <b-row v-if="loading" style="height: 350px;" class="text-center">
          <b-col
            sm="12"
            align-self="center"
          >
            <i2-data-loading/>
          </b-col>
        </b-row>
        <bar-chart v-if="!loading"
                   :horizontal="false"
                   height="500"
                   :stacked="true"
                   :toolbar="true"
                   :options="{xAxysGrid: true, yAxysGrid:true, dataLabels: false}"
                   :values="series"
                   :y-label="yLabel"
                   title="Quantidade de Números e Participantes"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import BarChart from '@/components/i2/Graph/BarChart.vue'
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'

export default {
  name: 'NumbersAndParticipantsPrizeDrawChart',
  components: {
    I2DataLoading,
    BarChart,
  },
  data() {
    return {
      dates: [],
      initialValue: [],
      loading: false,
      series: {},
      currentPromotion: {},
      yLabel: {
        text: 'Quantidades',
        format: val => new Intl.NumberFormat('pt-BR').format(val),
      },
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      return
    }
    this.currentPromotion = promotion

    this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`/metrics/promotion/${this.currentPromotion.uuid}/numbers-participants-prize-draws`)
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response
        })
    },
  },
}
</script>

<style scoped>

</style>
